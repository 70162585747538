import React from 'react';
import {RichText, Link} from '@sitecore-jss/sitecore-jss-react';
import ImageLink from '../../../core/Components/ImageLink';
import {DefaultProps, PropTypes} from '../../../core/Utils/SitecoreFields';
import Nav from './Components/Nav';
import Socials from './Components/Socials';

const Footer = ({fields, showMobileMenu}) => {
	return (
		<footer className={`global-footer${showMobileMenu ? ' is-hidden' : ''}`}>
			<div className="l-padding">
				<nav>
					<ul className="nav-footer has-5-items">
						<li className="brandImg">
							<ImageLink imageField={fields.logo} linkField={fields.logoLink} />
						</li>
						<Nav links={fields.columnOneLinks} columnClass="columns2" />
						<Nav links={fields.columnTwoLinks} columnClass="columns2" />
						<Nav links={fields.columnThreeLinks} columnClass="column" />
						<Socials fields={fields} />
					</ul>
				</nav>
			</div>
			{fields.friendlyLinks.length > 0 && (
				<div className="friendly-container">
					<span className="friendly-label">{fields.friendlyLinksLabel.value}</span>
					<div className="friendly-links">
						{fields.friendlyLinks.map(({fields}) => (
							<div className="friendly-link" key={fields.link.id}>
								<Link field={fields.link} rel="nofollow" title={fields.link.value.text}/>
							</div>
						))}
					</div>
				</div>
			)}
			<div className="subfooter-container">
				{fields.bottomLinks.map(({fields}) => (
					<div className="subfooter-link" key={fields.link.id}>
						<Link field={fields.link} />
					</div>
				))}

				<div className="subfooter-copyright">
					<RichText field={fields.copyright} />
				</div>
			</div>
		</footer>
	);
};

Footer.defaultProps = DefaultProps.SitecoreItem.inject({
	logo: DefaultProps.Image,
	logoLink: DefaultProps.Link,
	columnOneLInks: DefaultProps.Multilist,
	columnTwoLInks: DefaultProps.Multilist,
	columnThreeLInks: DefaultProps.Multilist,
	socials: DefaultProps.Multilist,
	copyright: DefaultProps.RichText,
	friendlyLinks: DefaultProps.Multilist,
	friendlyLinksLabel: DefaultProps.SingleLineText,
});

Footer.propTypes = PropTypes.SitecoreItem.inject({
	logo: PropTypes.Image,
	logoLink: PropTypes.Link,
	columnOneLInks: PropTypes.Multilist.inject({
		link: PropTypes.Link,
	}),
	columnTwoLInks: PropTypes.Multilist.inject({
		link: PropTypes.Link,
	}),
	columnThreeLInks: PropTypes.Multilist.inject({
		link: PropTypes.Link,
	}),
	socials: PropTypes.Multilist.inject({
		...PropTypes.ImageLink,
		title: PropTypes.SingleLineText,
	}),
	copyright: PropTypes.RichText,
	friendlyLinks: PropTypes.Multilist.inject({
		link: PropTypes.Link,
	}),
	friendlyLinksLabel: PropTypes.SingleLineText,
});

export default Footer;
