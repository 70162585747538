import React, {useEffect, useState} from 'react';
import {Link, Image, isExperienceEditorActive} from '@sitecore-jss/sitecore-jss-react';

const ImageContentLink = ({imageField, linkField, className}) => {
	const [rel, setRel] = useState('');
	useEffect(() => {
		const host = window.location.host;
		const relTemp =
			linkField.value.href && host && String(linkField.value.href).indexOf(host) >= 0
				? ''
				: 'nofollow';
		setRel(relTemp);
	}, []);

	return isExperienceEditorActive() || !linkField.value.href ? (
		<Image field={imageField} className={className} />
	) : (
		<Link field={linkField} rel={rel}>
			<Image field={imageField} className={className} />
		</Link>
	);
};

export default ImageContentLink;
